import React, { useContext } from 'react';
import { ThemeContext , LanguageContext} from 'ToolboxUtils/web/context/context';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import PropTypes from 'prop-types';

import { CguViewWrapper } from 'ToolboxComponents/webapp/pages/cgu-view/cgu-view.styles';

const CguView = ( {infos} ) => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const { owner, email } = infos;

  return (
    <>
    { language === 'fr' ? 
      <CguViewWrapper theme={theme}>
        <h2>CGU - Clause Données personnelles</h2>

        <br />

        <p>
          Lors de sa navigation sur la Webapp et/ou sur l’application mobile, l’Utilisateur est informé que des données relatives à sa connexion et à sa navigation sont traitées par {owner} et l’administrateur de la Plateforme, notamment par le biais de cookies et des formulaires qu’ils ont remplis. A titre d’exemple, en créant son Compte, l’Utilisateur complète des formulaires et indique des informations sur son identité, sa situation familiale, ses coordonnées, sa qualité, sa photographie, ses envies, sa géolocalisation et le type de transport utilisé. Les informations avec un astérisque sont obligatoires pour pouvoir créer un Compte. Ces informations sont purement déclaratives et l’Utilisateur est seul responsable de la qualité de leur saisie. En d’autres termes, aucune vérification n’est opérée par {owner}. Par ailleurs les données collectées par {owner} lors de la navigation de l’Utilisateur sont utilisées pour améliorer son expérience et plus particulièrement traiter les créations de compte, la saisie de données, le bon fonctionnement de la Webapp et/ou de l’application mobile, la réalisation de statistiques et un profilage non nominatif. Il est possible de désactiver les cookies en suivant les instructions fournies par les navigateurs toutefois cela peut ralentir ou limiter votre expérience sur la Webapp et/ou sur l’application mobile.
        </p>

        <br />

        <p>
          Les données des Utilisateurs sont conservées confidentiellement par {owner}, sont exclusivement traitées par {owner} et la société Agitateurs de Destinations Numériques et, sauf opposition de leur part, leurs partenaires.
        </p>

        <br />

        <p>
          Les Utilisateurs peuvent à tout moment se désinscrire et supprimer leurs données en accédant à leur Compte. Leurs données sont conservées le temps de la relation contractuelle. Au-delà, les données ne pourront être conservées que pour établir la preuve d’un droit ou d’un contrat, ou au titre du respect d’une obligation légale et conformément à la réglementation en vigueur. Les informations collectées par les cookies seront quant à elles conservées pendant treize (13) mois, à l’issue de cette période ces données seront supprimées.
        </p>

        <br />

        <p>
          Conformément aux dispositions légales relatives à la protection des données à caractère personnel, l’Utilisateur dispose notamment d'un droit d'accès, de rectification et d'opposition pour motif légitime s'agissant des informations le concernant et du droit d’organiser le sort de ses données post-mortem. Ces droits s’exercent par email à <a href={`mailto:${email}`}>{email}</a> en précisant dans l’objet « MES DONNEES » et en joignant la copie d’un justificatif d’identité. Pour en savoir plus consultez notre <Anchor code='privacy'>Politique de confidentialité</Anchor>.
        </p> 
        </CguViewWrapper> :
        <CguViewWrapper theme={theme}>
        <h2>General Conditions of Use - Personal data clause</h2>

        <br />

        <p>
          When browsing the Webapp and/or the mobile application, the User is informed that data relating to their connection and browsing is processed by {owner} and the administrator of the Platform, in particular through cookies and the forms they have filled. For example, by creating their Account, the User completes forms and indicates information on their identity, family situation, contact details, title, photograph, desires,  geolocation and the type of transport used. Information with an asterisk is mandatory to create an Account. This information is purely declarative and the User is solely responsible for the quality of its entry. In other words, {owner} does not check this information. The data collected by {owner}  while the User is browsing is used to improve their experience and more particularly to process account creations, data entry, the smooth operation of the Webapp and / or mobile application, the production of statistics and non-nominative profiling. It is possible to disable cookies by following the instructions provided by browsers. However this may slow down or limit your experience on the Webapp and/or on the mobile app.
        </p>

        <br />

        <p>
          {owner} maintains the privacy of Users’ data which is exclusively processed by  {owner} and the Agitateurs de Destinations Numériques company and, unless they object, its partners.
        </p>

        <br />

        <p>
          Users may at any time unsubscribe by accessing their Account and their data is kept for the duration of the contractual relationship. Beyond that, the data may only be kept to establish proof of a right or a contract, or in compliance with a legal obligation and in accordance with the regulations in force. The information collected by the cookies will be kept for thirteen (13) months. At the end of this period this data will be deleted.
        </p>

        <br />

        <p>
          In accordance with the legal provisions relating to the protection of personal data, the User has in particular a right of access, rectification and opposition on legitimate grounds with regard to the information concerning them and the right to organize the fate of their post-mortem data. These rights are exercised by email sent to  <a href={`mailto:${email}`}>{email}</a> specifying in the subject "MY DATA" and attaching a copy of proof of identity. To learn more, see our  <Anchor code='privacy'>Privacy Policy</Anchor>.
        </p> 
        </CguViewWrapper> 
    }
    </>
    
  );
}

CguView.propTypes = {
  infos: PropTypes.object.isRequired,
}

export default CguView;
